
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&display=swap');
.lucky-container {
  width: 100%;
  z-index: 200;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 40px;
  padding-top: 30px;
  height: 100%;
  position: relative;
  &:after {
    // content: '';
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -1; /* needed to keep in the background */
    background: url('~@/assets/images/bg.jpg') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: rgba(117, 45, 45, 0.1);
    z-index: 0;
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 3;
  }
  .button-lucky {
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #458802, #7bc514);
    min-width: 120px;
    box-shadow: 3.6641px 3.4981px 2.3321px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 18px;
    font-family: 'Myriad Pro';
    text-transform: uppercase;
    display: flex;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    letter-spacing: 1px;
    height: 40px;
    vertical-align: middle;
    line-height: 30px;
    border: 0px;
    border-radius: 20px;
    &:hover {
      filter: brightness(150%);
    }
    &:focus,
    &:active {
      filter: brightness(150%);
      border: 2px solid #f9ff00;
    }
    &.info {
      position: absolute;
      left: 0;
      right: 0;
      top: 60%;
      height: 20px;
      width: 60px;
      margin: auto;
      min-width: unset;
      padding: 0;
      display: flex;
      // align-items: center;
      justify-content: center;
      box-shadow: unset;
      line-height: 21px;
      font-size: 20px;
      background: rgb(201, 0, 0) !important;
      background: radial-gradient(
        circle,
        rgba(201, 0, 0, 1) 0%,
        rgba(92, 0, 0, 1) 60%
      ) !important;
    }
  }
  .box-1 {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    border: 2px solid #009444;
  }
  .box-2 {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    border: 2px solid #009444;
  }
  .title-lucky {
    text-align: center;
    margin: 0px 0px 50px 0px;
    padding: 0 5px;
    img {
      width: 100%;
      max-width: 500px;
      filter: drop-shadow(5.9px 5.9px 4.2px rgba(0, 0, 0, 0.5));
    }
  }
  .content-lucky {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .left {
      flex: 0 0 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .fish-img {
        max-width: 250px;
        margin-bottom: 15px;
      }
      .buy-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        max-width: 300px;
        .total-amount,
        .title {
          font-family: 'Montserrat', sans-serif;
          font-size: 25px;
          color: #f9ff00;
          font-weight: 700;
          letter-spacing: 0.5px;
          background: transparent;
        }
        img {
          max-width: 150px;
        }
        .input-group {
          margin: 10px 5px;
          max-width: 270px;
          display: flex;
          justify-content: center;
          button {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0 none !important;
            padding: 5px 5px;
            background: transparent;
            flex: 0 0 30px;
            i,
            svg {
              font-size: 25px;
              color: #85e8ff;
            }
          }
          .input-amount {
            background: white;
            border: 0;
            height: 30px;
            color: #8b8686 !important;
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            flex: 0 0 210px;
            max-width: 200px;
          }
        }
      }
    }
    .center {
      flex: 0 0 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      .box-spin {
        position: relative;
        height: 550px;
        width: 550px;

        .wheel-wrapper {
          width: 550px;
          height: 550px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .wheel-pointer {
          width: calc(100% / 3);
          height: calc(100% / 2.3);
          border-radius: 1000px;
          position: absolute;
          z-index: 990;
          cursor: pointer;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          img {
            z-index: 990;
            width: 100%;
            height: auto;
          }
        }
        .wheel-bg {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          transition: transform 4s ease-in-out;
          background: url(~@/assets/images/luckyspin/spin.png);
          background-size: 100%100%;
          &.freeze {
            transition: none;
            background: red;
          }
        }

        .prize-list {
          width: 100%;
          height: 100%;
          position: relative;
          text-align: center;
        }

        .prize-item-wrapper {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% / 2);
          height: calc(100% / 2);
        }

        .prize-item {
          width: 100%;
          height: 100%;
          transform-origin: bottom;

          .prize-name {
            padding: 0% 0 0% 20%;
            transform: rotate(90deg);
            display: flex;
            font-size: 20px;
            color: white;
            font-weight: 600;
            text-align: center;
            justify-content: center;
            align-self: center;
            align-items: center;
            margin: auto;
            text-transform: capitalize;
            img {
              transform: rotate(-90deg) translateY(10px) translateX(0px);
              width: 80px;
              filter: drop-shadow(0px 0px 2px rgba(255, 0, 0, 0.5));
              @media (max-width: 1439px) {
                width: 75px;
              }
            }
            &.item_7777 {
              img {
                width: 95px;
                @media (max-width: 480px) {
                  width: 75px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 424px) {
                  width: 70px;
                  transform: rotate(-85deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 374px) {
                  width: 60px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
              }
            }
            &.item_5555 {
              img {
                width: 90px;
                @media (max-width: 767px) {
                  width: 80px;
                }
                @media (max-width: 480px) {
                  width: 65px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 424px) {
                  width: 55px;
                  transform: rotate(-85deg) translateY(10px) translateX(0px);
                }
                @media (max-width: 374px) {
                  width: 50px;
                  transform: rotate(-90deg) translateY(10px) translateX(0px);
                }
              }
            }
          }

          .prize-icon {
          }
        }
      }
      .prize-item .prize-name {
        font-size: 25px !important;
        font-weight: 700;
      }
      .prize-list .prize-item-wrapper .prize-name.goodluck {
        padding: 0 15px 0 20%;
        img {
          width: 40px;
        }
      }
      .prize-list .prize-item-wrapper .prize-name.ticket {
        padding: 0 0 0 25%;
        transform: rotate(87deg);
        img {
          transform: rotate(93deg) translateY(10px) translateX(0px);
          width: 50px;
        }
      }
      .amount-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        flex: 1;
        width: 100%;
        max-width: 245px;
        img {
          max-width: 100px;
          margin-right: 5px;
        }
        .amount {
          box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
          background-color: #282627;
          border-radius: calc(30px * 1.5);
          height: 30px;
          width: 100%;
          display: flex;
          font-family: 'Montserrat', sans-serif;
          font-size: 22px;
          border: #fff 1px solid;
          color: #f9ff00;
          font-weight: 700;
          text-align: center;
          vertical-align: middle;
          justify-content: center;
          align-items: center;
          letter-spacing: 1px;
        }
      }
      .action-box {
        display: flex;
        justify-content: space-around;
        flex: 1;
        width: 100%;
        max-width: 320px;
      }
    }
    .right {
      flex: 0 0 25%;
      flex-direction: column;
      height: 100%;
      .mission-box {
        height: 400px;
        position: relative;
        padding: 30px 5px 15px 10px;
        .mission-title {
          position: absolute;
          top: -25px;
          height: 50px;
          width: 80%;
          min-width: 250px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          img {
            height: 100%;
            width: auto;
          }
        }
        .mission-content {
          overflow-x: hidden;
          max-height: 375px;
          height: 100%;
          padding-top: 35px;
          padding-right: 5px;
          overflow-y: auto;
          border-radius: 15px;
          &::-webkit-scrollbar {
            width: 10px;
            height: 3px;
            border-radius: 1.5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.288);
            border-radius: 5px;
            &:hover {
              background: #ffffffad;
            }
            margin: {
              bottom: 65px;
              top: 65px;
            }
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #ffffff;
            border-radius: 5px;

            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #f9ff00;
          }

          .item-mission {
            border: 1px solid #a1a1a1;
            position: relative;
            background: #282627;
            border-radius: 12px;
            margin-bottom: 40px;
            &:last-child {
              margin-bottom: 5px;
            }
            // height: 250px;
            .item-icon {
              position: absolute;
              width: 70px;
              height: 70px;
              border-radius: 50px;
              left: 20px;
              top: -35px;
              z-index: 1;
              @media (min-width: 1200px) and (max-width: 1440px) {
                left: 0;
              }
              @media (max-width: 425px) {
                width: 50px;
                height: 50px;
                left: 0;
                top: -25px;
              }
            }
            .item-title {
              position: absolute;
              height: 40px;
              width: 80%;
              max-width: 275px;
              min-width: 235px;
              top: -20px;
              background: rgba(23, 192, 1, 1) !important;
              background: linear-gradient(
                90deg,
                rgba(0, 56, 1, 1) 0%,
                rgba(23, 192, 1, 1) 50%,
                rgba(0, 56, 1, 1) 100%
              ) !important;
              box-shadow: 2px 3.4981px 2.3321px rgba(0, 0, 0, 0.3);
              left: 60px;
              border-radius: 10px;
              padding-left: 20px;
              color: #fff;
              font-weight: 600;
              display: flex;
              justify-content: center;
              padding-right: 8px;
              align-items: center;
              text-align: center;
              border: 0px;
              @media (min-width: 1200px) and (max-width: 1440px) {
                left: 50px;
              }
              @media (max-width: 425px) {
                left: 30px;
                min-width: 200px;
              }
            }
            .item-content {
              height: 100%;
              padding: 40px 10px 5px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .content-detail {
                color: #fff;
                margin: {
                  bottom: 5px;
                  top: 5px;
                }
                text-align: left;
              }
              .content-progress {
              }
              .content-reward {
                margin-top: 15px;
                .reward-title {
                  color: #f9ff00;
                  text-transform: capitalize;
                  font-family: 'UTM Helve';
                  font-size: 16px;
                  font-weight: bold;
                }
                .reward-content {
                  margin-top: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .reward-left {
                    flex: 1 1 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 2px solid white;
                    img {
                      height: 30px;
                      margin-left: 5px;
                    }
                    span {
                      color: #fff;
                      font-size: 15px;
                      font-weight: 600;
                    }
                  }
                  .reward-right {
                    flex: 1 1 auto;
                    padding-left: 10px;
                    color: #fff;
                    display: flex;
                    justify-content: space-around;
                    span {
                      color: #f9ff00;
                      font-weight: bold;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .balance-box {
        margin: 5px 0;
        height: 200px;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px 15px 15px;
        .balance-title {
          position: absolute;
          height: 40px;
          width: 80%;
          // max-width: 275px;
          // min-width: 250px;
          border: 1px solid #ffffff;
          top: -20px;
          background: rgba(23, 192, 1, 1) !important;
          background: linear-gradient(
            90deg,
            rgba(0, 56, 1, 1) 0%,
            rgba(23, 192, 1, 1) 50%,
            rgba(0, 56, 1, 1) 100%
          ) !important;
          box-shadow: 2px 3.4981px 2.3321px rgba(0, 0, 0, 0.3);
          left: 0;
          right: 0;
          margin: auto;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 700;
          @media (max-width: 400px){
            text-align: center;
            line-height: 1;
          }
        }
        .amount {
          color: #f9ff00;
          text-transform: capitalize;
          font-family: 'UTM Helve';
          font-size: 18px;
          font-weight: bold;
        }
        .bonus {
          position: relative;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          border-right: 0 !important;
          &:after {
            content: '';
            height: 70px;
            width: 2px;
            background: #fff;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 0;
            position: absolute;
          }
          .button-withdraw {
            //   &.border {
            //     border: 1px solid #fff;
            //   }
            border: 0px;
            background-color: #5da000;
            background-image: conic-gradient(from 1turn, #458802, #7bc514);
            min-width: 120px;
            box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
            color: #fff;
            font-size: 15px;
            font-family: 'Myriad Pro';
            text-transform: uppercase;
            display: flex;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            letter-spacing: 1px;
            height: 30px;
            vertical-align: middle;
            line-height: 20px;
            border-radius: 20px;
            &:hover {
              filter: brightness(150%);
            }
            &:focus,
            &:active {
              filter: brightness(150%);
              border: 2px solid #f9ff00;
            }
          }
        }
        .balance-cur {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          border-left: 0 !important;
        }
        .bonus,
        .balance-cur {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 35px 5px 20px 5px;
          flex: 1;
          background: #282627;
          border: 1px solid #a1a1a1;
          height: 100%;
        }
      }
    }
    @media (min-width: 1200px) and (max-width: 1440px) {
      .left {
        flex: 0 0 20%;
        .fish-img {
          width: 210px;
        }
      }
      .center {
        flex: 0 0 50%;
        .box-spin {
          width: 525px;
          height: 525px;
          .wheel-wrapper {
            width: 525px;
            height: 525px;
          }
        }
      }
      .right {
        flex: 0 0 30%;
      }
    }
    @media (max-width: 1440px) {
      flex-wrap: wrap;
      .left {
        flex: 0 0 30%;
      }

      .center {
        flex: 1 1 auto;
      }
      .right {
        flex: 0 0 100%;
        flex-direction: column-reverse;
        display: flex;
        align-items: flex-start;
        margin-top: 50px;
        .mission-box,
        .balance-box {
          flex: 1;
          margin: 5px;
          width: 100%;
        }
        .mission-box {
          .mission-content {
            .item-mission {
              .item-content {
                .content-reward {
                  .reward-content {
                    .reward-right {
                      justify-content: center;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 1199px) {
      //   .center {
      //     flex: 0 0 100%;
      //     margin-bottom: 50px;
      //   }
      // }
      // @media (max-width: 991px) {
      align-items: flex-start;
      .left {
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .center {
        flex: 0 0 100%;
        margin-bottom: 50px;
      }
      .right {
        flex: 0 0 50%;
        flex-direction: column-reverse;
        display: flex;
        align-items: flex-start;
        margin-top: unset;
        .mission-box,
        .balance-box {
          flex: 1;
          margin: 5px;
          width: 100%;
        }
        .mission-box {
          .mission-content {
            .item-mission {
              .item-content {
                .content-reward {
                  .reward-content {
                    .reward-right {
                      justify-content: center;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .center {
        .box-spin {
          height: 480px;
          width: 480px;

          .wheel-wrapper {
            width: 480px;
            height: 480px;
          }
          .prize-item {
            .prize-name {
              padding: 0% 0 0% 20%;
              transform: rotate(90deg);
              img {
                width: 60px;
              }
            }
          }
          .prize-list .prize-item-wrapper .prize-name.goodluck {
            padding: 0 15px 0 30%;
            // transform: rotate(87deg);
            img {
              width: 25px;
            }
          }
          .prize-list .prize-item-wrapper .prize-name.ticket {
            padding: 0 15px 0 40%;
            transform: rotateZ(87deg);
            img {
              width: 25px;
            }
          }
        }
      }
      .left {
        flex: auto;
        width: 100%;
        position: relative;
        .fish-img {
          //   position: absolute;
          //   right: 0;
          //   top: -50px;
          //   overflow-x: hidden;
          //   max-width: 200px;
        }
        .buy-box,
        .buy-action {
          z-index: 10;
        }
      }
      .right {
        flex: auto;
        width: 100%;
        padding: 15px;
      }
    }
    @media (max-width: 480px) {
      .center {
        .box-spin {
          height: 425px;
          width: 425px;

          .wheel-wrapper {
            width: 425px;
            height: 425px;
          }
        }
      }
    }
    @media (max-width: 424px) {
      .center {
        .box-spin {
          height: 370px;
          width: 370px;

          .wheel-wrapper {
            width: 370px;
            height: 370px;
          }
          .prize-item {
            .prize-name {
              padding: 0% 0 0% 20%;
              transform: rotate(85deg);
              img {
                width: 45px;
              }
            }
          }
          .prize-list .prize-item-wrapper .prize-name.goodluck {
            padding: 0 15px 0 30%;
            transform: rotate(90deg);
            img {
              width: 25px;
            }
          }
          .prize-list .prize-item-wrapper .prize-name.ticket {
            padding: 0 5px 0 30%;
            transform: rotate(87deg);
            img {
              width: 25px;
            }
          }
        }
      }
    }
    @media (max-width: 374px) {
      .center {
        .box-spin {
          height: 310px;
          width: 310px;

          .wheel-wrapper {
            width: 310px;
            height: 310px;
          }
          .prize-item {
            .prize-name {
              padding: 0% 0 0% 20%;
              transform: rotate(90deg);
              img {
                width: 40px;
              }
            }
          }
          .prize-list .prize-item-wrapper .prize-name.goodluck {
            padding: 0 15px 0 30%;
            transform: rotate(90deg);
            img {
              width: 20px;
            }
          }
          .prize-list .prize-item-wrapper .prize-name.ticket {
            padding: 0 15px 0 40%;
            transform: rotate(87deg);
            img {
              width: 20px;
            }
          }
        }
      }
      .right {
        .balance-box {
          flex-direction: column;
          height: 100%;
          .bonus,
          .balance-cur {
            flex: 1 1 100%;
            width: 100%;
            margin-bottom: 25px;
            min-height: 100px;
            &:after {
              display: none;
            }
            border-radius: 15px;
            justify-content: center;
            .button-withdraw {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}
.input-group {
  display: flex;
}
.title-withdraw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;
  border-bottom: 2px solid #ccc;
  h2 {
    margin: 0;
    text-align: left;
    padding-left: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 25px;
    text-transform: uppercase;
    @media (max-width: 375px) {
      text-align: center;
      font-size: 22px;
    }
  }
  span {
    font-size: 30px;
    line-height: 30px;
    font-weight: 800;
    color: #fff800;
    position: absolute;
    top: -10px;
    right: -5px;
    cursor: pointer;
  }
}
.my-3 {
  margin: {
    top: 1.25rem;
    bottom: 1.25rem;
  }
}
.text-warning {
  color: #fff800 !important;
  font-weight: 600;
  margin: {
    top: 10px;
    bottom: 20px;
  }
}
.form-wd {
  padding-top: 20px;
  label {
    color: #fff;
  }
  input {
    &:disabled {
      background-color: #464445 !important;
    }
    background-color: #282627;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
  }
}
